import request from '@/utils/request'
const isProd = process.env.NODE_ENV === 'production'

console.log('isProd',isProd)
const serviceApi = {
  getTransferJmhList:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getJmhghList',
	getTransferInfoJmh:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getJmhghInfo',
	handleJmh:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=auditJmhgh',
	
	getAccountCancelList:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getxhList',
	getAccountCancelInfo:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getxhInfo',
	handleAccountCancel:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=auditSbxh',
	
	waterChangeList:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getYsxzbgList',
	waterChangeInfo:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getYsxzInfo',
	handlewaterChange:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=auditYsxz',
	
	multiPopulationChangeAppList:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getDrkbgList',
	multiPopulationChangeAppInfo:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getDrkbgInfo',
	handleMultiPopulationChange:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=auditDrkbg',
	
	/*复接报停 1 复接 2居民报停 3工商报停 */
	waterReconnectionList:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getSbfjBtList',
	waterReconnectionInfo:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getSbfjBtInfo',
	handlewaterReconnection:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=auditSbfjBt',
	
	/*单位过户 */
	unitTransferList:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getDwghList',
	unitTransferInfo:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getDwghInfo',
	handleUnitTransfer:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=auditDwgh',
	
	/*水费账号变更*/
	waterAccountChangeList:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getSfzhbgList',
	waterAccountChangeInfo:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getSfzhbgInfo',
	handleWaterAccountChange:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=auditSfzhbg',
	
	/*个体散户出户*/
	waterOutList:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getGtshsbchList',
	waterOutInfo:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getGrshsbchInfo',
	handleWaterOut:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=auditGrshsbch',
	
	/*工商户接用水申请*/
	waterApplyList:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getGshjyssqList',
	waterApplyInfo:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getGshjyssqInfo',
	handleWaterApply:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=auditGshjyssq',
	
	/*整体水表出户改造*/
	waterOutReformList:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getZtchgzList',
	waterOutReformInfo:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getZtchgzInfo',
	handleWaterOutReform:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=auditZtchgz',
	
	/*施工水表报建*/
	waterReportList:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getSgsbbjList',
	waterReportInfo:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getSgsbbjInfo',
	handleWaterReport:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=auditSgsbbj',
	
	/*委托设计申请*/
	waterDesignList:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getWtsjsqList',
	waterDesignInfo:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getWtsjsqInfo',
	handleWaterDesign:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=auditWtsjsq',
  
  
  //工商户销帐
  cancelAccountList:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getGshsfxzList',
  cancelAccountInfo:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getGshsfxzInfo',
  handleCancelAccount:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=auditGshsfxh',
  
  
  // 增值税发票预约
  invoApplyList:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getZzsfpyyList',
  invoApplyInfo:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getZzsfpyyInfo',
  handleInvoApply:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=auditZzsfpyy',
  
  // 多户号关联
  invoLinkList:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getDzfpglList',
  invoLinkInfo:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=getDzfpglInfo',
  handleInvoLink:(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=auditDzfpgl',

  _getSourceList:(isProd?'':'/api')+'/admin.php?c=customer&a=getBusinesslist',
}

//来源
export function _getSourceList(parameter){
    return request({
        url:serviceApi._getSourceList,
        method: 'post',
        data: parameter
    })
}

//多户号关联
export function invoLinkList(parameter){
    return request({
        url:serviceApi.invoLinkList,
        method: 'post',
        data: parameter
    })
}

export function invoLinkInfo(parameter){
    return request({
        url:serviceApi.invoLinkInfo,
        method: 'post',
        data: parameter
    })
}

export function handleInvoLink(parameter){
    return request({
        url:serviceApi.handleInvoLink,
        method: 'post',
        data: parameter
    })
}

//增值税发票预约
export function invoApplyList(parameter){
    return request({
        url:serviceApi.invoApplyList,
        method: 'post',
        data: parameter
    })
}

export function invoApplyInfo(parameter){
    return request({
        url:serviceApi.invoApplyInfo,
        method: 'post',
        data: parameter
    })
}

export function handleInvoApply(parameter){
    return request({
        url:serviceApi.handleInvoApply,
        method: 'post',
        data: parameter
    })
}


/***********工商户销帐***********/
export function cancelAccountList(parameter){
    return request({
        url:serviceApi.cancelAccountList,
        method: 'post',
        data: parameter
    })
}
export function cancelAccountInfo(parameter){
    return request({
        url:serviceApi.cancelAccountInfo,
        method: 'post',
        data: parameter
    })
}
export function handleCancelAccount(parameter){
    return request({
        url:serviceApi.handleCancelAccount,
        method: 'post',
        data: parameter
    })
}

/***********委托设计申请***********/
export function waterDesignList(parameter){
    return request({
        url:serviceApi.waterDesignList,
        method: 'post',
        data: parameter
    })
}

//详情 
export function waterDesignInfo(parameter){
    return request({
        url:serviceApi.waterDesignInfo,
        method: 'post',
        data: parameter
    })
}

//审核
export function handleWaterDesign(parameter){
	return request({
		url:serviceApi.handleWaterDesign,
		method:'post',
		data: parameter
	})
}

/***********施工水表报建***********/
export function waterReportList(parameter){
    return request({
        url:serviceApi.waterReportList,
        method: 'post',
        data: parameter
    })
}

//详情 
export function waterReportInfo(parameter){
    return request({
        url:serviceApi.waterReportInfo,
        method: 'post',
        data: parameter
    })
}

//审核
export function handleWaterReport(parameter){
	return request({
		url:serviceApi.handleWaterReport,
		method:'post',
		data: parameter
	})
}

/***********整体水表出户改造***********/
export function waterOutReformList(parameter){
    return request({
        url:serviceApi.waterOutReformList,
        method: 'post',
        data: parameter
    })
}

//详情 
export function waterOutReformInfo(parameter){
    return request({
        url:serviceApi.waterOutReformInfo,
        method: 'post',
        data: parameter
    })
}

//审核
export function handleWaterOutReform(parameter){
	return request({
		url:serviceApi.handleWaterOutReform,
		method:'post',
		data: parameter
	})
}

/***********个体散户出户***********/
export function waterApplyList(parameter){
    return request({
        url:serviceApi.waterApplyList,
        method: 'post',
        data: parameter
    })
}

//详情 
export function waterApplyInfo(parameter){
    return request({
        url:serviceApi.waterApplyInfo,
        method: 'post',
        data: parameter
    })
}

//审核
export function handleWaterApply(parameter){
	return request({
		url:serviceApi.handleWaterApply,
		method:'post',
		data: parameter
	})
}

/***********个体散户出户***********/
export function waterOutList(parameter){
    return request({
        url:serviceApi.waterOutList,
        method: 'post',
        data: parameter
    })
}

//详情 
export function waterOutInfo(parameter){
    return request({
        url:serviceApi.waterOutInfo,
        method: 'post',
        data: parameter
    })
}

//审核
export function handleWaterOut(parameter){
	return request({
		url:serviceApi.handleWaterOut,
		method:'post',
		data: parameter
	})
}


/***********水费账号变更***********/
export function waterAccountChangeList(parameter){
    return request({
        url:serviceApi.waterAccountChangeList,
        method: 'post',
        data: parameter
    })
}

//详情 
export function waterAccountChangeInfo(parameter){
    return request({
        url:serviceApi.waterAccountChangeInfo,
        method: 'post',
        data: parameter
    })
}

//审核
export function handleWaterAccountChange(parameter){
	return request({
		url:serviceApi.handleWaterAccountChange,
		method:'post',
		data: parameter
	})
}


/***********单位过户***********/
export function unitTransferList(parameter){
    return request({
        url:serviceApi.unitTransferList,
        method: 'post',
        data: parameter
    })
}

//详情 
export function unitTransferInfo(parameter){
    return request({
        url:serviceApi.unitTransferInfo,
        method: 'post',
        data: parameter
    })
}

//审核
export function handleUnitTransfer(parameter){
	return request({
		url:serviceApi.handleUnitTransfer,
		method:'post',
		data: parameter
	})
}




/********居民户过户******/

//列表
export function getTransferJmhList(parameter){
    return request({
        url:serviceApi.getTransferJmhList,
        method: 'post',
        data: parameter
    })
}

//详情 
export function getTransferInfoJmh(parameter){
    return request({
        url:serviceApi.getTransferInfoJmh,
        method: 'post',
        data: parameter
    })
}

//审核
export function handleJmh(parameter){
	return request({
		url:serviceApi.handleJmh,
		method:'post',
		data: parameter
	})
}

/**********居民户水表销户**************/

//列表
export function getAccountCancelList(parameter){
    return request({
        url:serviceApi.getAccountCancelList,
        method: 'post',
        data: parameter
    })
}

//详情 
export function getAccountCancelInfo(parameter){
    return request({
        url:serviceApi.getAccountCancelInfo,
        method: 'post',
        data: parameter
    })
}

//审核
export function handleAccountCancel(parameter){
	return request({
		url:serviceApi.handleAccountCancel,
		method:'post',
		data: parameter
	})
}


/*********居民户用水性质变更**********/
//列表
export function waterChangeList(parameter){
    return request({
        url:serviceApi.waterChangeList,
        method: 'post',
        data: parameter
    })
}

//详情 
export function waterChangeInfo(parameter){
    return request({
        url:serviceApi.waterChangeInfo,
        method: 'post',
        data: parameter
    })
}

//审核
export function handlewaterChange(parameter){
	return request({
		url:serviceApi.handlewaterChange,
		method:'post',
		data: parameter
	})
}

/********多人口变更申请***********/
//列表
export function multiPopulationChangeAppList(parameter){
    return request({
        url:serviceApi.multiPopulationChangeAppList,
        method: 'post',
        data: parameter
    })
}

//详情 
export function multiPopulationChangeAppInfo(parameter){
    return request({
        url:serviceApi.multiPopulationChangeAppInfo,
        method: 'post',
        data: parameter
    })
}

//审核
export function handleMultiPopulationChange(parameter){
	return request({
		url:serviceApi.handleMultiPopulationChange,
		method:'post',
		data: parameter
	})
}


/***********居民水表复接***********/
export function waterReconnectionList(parameter){
    return request({
        url:serviceApi.waterReconnectionList,
        method: 'post',
        data: parameter
    })
}

//详情 
export function waterReconnectionInfo(parameter){
    return request({
        url:serviceApi.waterReconnectionInfo,
        method: 'post',
        data: parameter
    })
}

//审核
export function handlewaterReconnection(parameter){
	return request({
		url:serviceApi.handlewaterReconnection,
		method:'post',
		data: parameter
	})
}
