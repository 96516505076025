<template>
    <page-header-wrapper>
        <template slot="extra">
            <workbench-tab @change="getTabId" v-model="active"></workbench-tab>
        </template>
        <a-card :bordered="false">
            <div class="table-page-search-wrapper" v-if="$auth('service_zsys.query')">
                <a-form layout="inline">
                    <a-row :gutter="6">
                        <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="8" :xxl="6">
                            <a-form-item label="时间筛选：">
                                <a-range-picker @change="getDate" v-model="queryParam.time" style="width:220px" />
                            </a-form-item>
                        </a-col>
                        <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" :xxl="4">
                            <a-form-item label="来源">
                                <a-select v-model="queryParam.dataSource" placeholder="请选择" default-value="0"
                                    style="width:100px">
                                    <a-select-option value="0">全部</a-select-option>
                                    <a-select-option :value="data.id" :title="data.name" v-for="(data, idx) in typeData"
                                        :key="idx">{{ data.name }}</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" :xxl="4">
                            <a-form-item label="状态">
                                <a-select v-model="queryParam.auditStatus" placeholder="请选择" default-value="0"
                                    @change="getStatus" style="width:100px">
                                    <a-select-option :value="index" v-for="(item, index) in statusMap" :key="index">{{
                                        item.text }}</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :xs="24" :sm="24" :md="13" :lg="13" :xl="9" :xxl="7">
                            <a-form-item label="户名">
                                <a-input style="max-width: 220px;" placeholder="请输入" v-model="queryParam.keyword" />
                            </a-form-item>
                        </a-col>
                        <a-col :xs="24" :sm="24" :md="4" :lg="4" :xl="3" :xxl="3">
                            <span class="table-page-search-submitButtons">
                                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                                <a-button style="margin-left: 8px" @click="reset">重置</a-button>
                            </span>
                        </a-col>
                    </a-row>
                </a-form>
            </div>


            <s-table ref="table" size="default" :rowKey="(record) => record.id" :columns="columns" :data="loadData"
                showPagination="auto" :scroll="{ x: 1500 }" :pageNum="pageNum">
                <span slot="serial" slot-scope="text, record, index">
                    {{ index + 1 }}
                </span>
                <span slot="source" slot-scope="data_source">
                    <a-tag :color="getSource(data_source).color">{{ getSource(data_source).name }}</a-tag>
                </span>
                <span slot="status" slot-scope="audit_status">
                    <a-badge :class="'badge' + audit_status" :status="audit_status | statusTypeFilter"
                        :text="audit_status | statusFilter" />
                </span>
                <span slot="address" slot-scope="assress">
                    <ellipsis :length="30" tooltip class="ellip">
                        {{ assress || '-' }}
                    </ellipsis>
                </span>
                <span slot="time" slot-scope="create_time">
                    {{ create_time | time }}
                </span>
                <span slot="update_time" slot-scope="update_time,record">
                    <span v-if="update_time > record.create_time">{{ update_time | time }}</span>
                    <span v-else>-</span>
                </span>
                <span slot="action" slot-scope="text, record">
                    <template>
                        <!-- <a @click="handleEdit(record)" v-if="record.audit_status==1" v-action:audit>处理</a>
						<a @click="handleWatch(record)" v-else v-action:view>查看</a> -->
                        <!-- <a-divider type="vertical" />
						<a @click="handlePre(record)" v-action:preview>预览</a> -->
                        <template v-if="record.audit_status == 1">
                            <span v-if="$auth('service_zsys.audit')" class="btn_pass" @click="handleEdit(record, 2)">通过</span>
                            <span v-if="$auth('service_zsys.audit')" class="btn_reject" @click="handleEdit(record, 3)">拒绝</span>
                        </template>
                        <span v-if="$auth('service_zsys.view')" class="btn_btn" @click="handleWatch(record)">详情</span>
                        <!-- <span v-action:preview class="btn_btn" @click="printFile(record)">打印</span> -->
                        <a-tooltip v-if="record.audit_status == 3">
                            <template slot="title">
                                {{ record.audit_opinion }}
                            </template>
                            <span class="btn_btn">拒绝理由</span>
                        </a-tooltip>
                    </template>
                </span>
            </s-table>

            <!-- 详情 -->
            <a-drawer class="myDrawer" title="业务办理详情" width="500px" placement="right" :visible="visible" @close="onClose">
                <div class="drawer_main">
                    <div class="drawer_main_title">{{ $sys.basic }}</div>
                    <div class="drawer_main_content">
                        <div class="drawer_main_item">
                            <div class="drawer_main_item_l">{{ $sys.accName }}</div>
                            <div class="drawer_main_item_r">{{ info && info.customer_name || '-' }}</div>
                        </div>
                        <div class="drawer_main_item">
                            <div class="drawer_main_item_l">{{ $sys.buildAdd }}</div>
                            <div class="drawer_main_item_r">{{ info && info.address || '-' }}</div>
                        </div>
                        <div class="drawer_main_item">
                            <div class="drawer_main_item_l">{{ $sys.meterCaliber }}</div>
                            <div class="drawer_main_item_r">{{ info && info.caliber || '-' }}</div>
                        </div>
                        <div class="drawer_main_item">
                            <div class="drawer_main_item_l">{{ $sys.contact }}</div>
                            <div class="drawer_main_item_r">{{ info && info.name || '-' }}</div>
                        </div>
                        <div class="drawer_main_item">
                            <div class="drawer_main_item_l">{{ $sys.tel }}</div>
                            <div class="drawer_main_item_r">{{ info && info.mobile || '-' }}</div>
                        </div>
                        <div class="drawer_main_item">
                            <div class="drawer_main_item_l">审核状态</div>
                            <div class="drawer_main_item_r a_pass" v-if="info && info.audit_status == 2">审核通过</div>
                            <div class="drawer_main_item_r a_reject" v-else-if="info && info.audit_status == 3">审核拒绝</div>
                            <div class="drawer_main_item_r" v-else>-</div>
                        </div>
                        <div class="drawer_main_item" v-if="info && info.audit_status == 3">
                            <div class="drawer_main_item_l">{{ $sys.auditReason }}</div>
                            <div class="drawer_main_item_r">{{ info && info.audit_opinion || '-' }}</div>
                        </div>
                    </div>
                    <div class="drawer_main_title">上传附件</div>
                    <div class="drawer_main_content">
                        <div v-for="(item, index) in attachment" :key="index" v-show="attachment">
                            <div class="drawer_main_item">
                                <div class="drawer_main_item_l">{{ type_name[index] || '' }}</div>
                                <div class="drawer_main_item_r imgList">
                                    <div class="imgListContainer">
                                        <img :src="checkPrefixWithHttp(item) ? item : prefix + item" preview="1" preview-text=""
                                            v-if="item">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="drawer_main_item" v-show="photo1.length>0">
              <div class="drawer_main_item_l">用户接用水申请</div>
              <div class="drawer_main_item_r imgList">
                <div v-for="(item, index) in photo1" :key="index" class="imgListContainer">
                  <img :src="checkPrefixWithHttp(item)?item:prefix+item" preview="1" preview-text="">
                </div>
              </div>
            </div>
            <div class="drawer_main_item" v-show="photo2.length>0">
              <div class="drawer_main_item_l">供用水合同</div>
              <div class="drawer_main_item_r imgList">
                <div v-for="(item, index) in photo2" :key="index" class="imgListContainer">
                  <img :src="checkPrefixWithHttp(item)?item:prefix+item" preview="1" preview-text="">
                </div>
              </div>
            </div>
            <div class="drawer_main_item" v-show="photo3.length>0">
              <div class="drawer_main_item_l">肥西供水集团有限公司水费定期借记业务授权与协议书</div>
              <div class="drawer_main_item_r imgList">
                <div v-for="(item, index) in photo3" :key="index" class="imgListContainer">
                  <img :src="checkPrefixWithHttp(item)?item:prefix+item" preview="1" preview-text="">
                </div>
              </div>
            </div>
            <div class="drawer_main_item" v-show="photo4.length>0">
              <div class="drawer_main_item_l">用户委托书</div>
              <div class="drawer_main_item_r imgList">
                <div v-for="(item, index) in photo4" :key="index" class="imgListContainer">
                  <img :src="checkPrefixWithHttp(item)?item:prefix+item" preview="1" preview-text="">
                </div>
              </div>
            </div> -->
                    </div>
                </div>
                <!-- <div class="drawer_footer" v-show="!isChecked">
					<div class="drawer_footer_right">
						<a-button type="primary" @click="onClose(2)">{{$sys.pass}}</a-button>
						<a-button type="danger" class="mgl20" @click="onClose(3)">{{$sys.reject}}</a-button>
					</div>
				</div> -->
            </a-drawer>
            <!-- 理由 -->
            <a-modal class="modal_foot_center" v-model="modalVisible" title="理由" centered @ok="closeModal(1)"
                @cancel="closeModal(0)">
                <div>
                    <a-textarea placeholder="请输入回复内容" :rows="6" v-model="reason" />
                </div>
            </a-modal>
        </a-card>
    </page-header-wrapper>
</template>

<script>
var init = null;
import moment from 'moment'
import { getUrlKey } from '@/utils'
import { Modal, message } from 'ant-design-vue'
import {
    STable,
    Ellipsis
} from '@/components'
import {
    waterReportList,
    waterReportInfo,
    handleWaterReport,
    _getSourceList,
} from '@/api/service'
import workbenchTab from './workbenchTab'
import { prefix, getSource, checkPrefixWithHttp } from '@/utils'
const columns = [{
    title: '序号',
    scopedSlots: {
        customRender: 'serial'
    },
    width: '90px',
    align: 'center'
},
{
    title: '数据来源',
    dataIndex: 'data_source',
    scopedSlots: {
        customRender: 'source'
    },
    align: 'center',
    width: '100px'
},
{
    title: '状态',
    dataIndex: 'audit_status',
    scopedSlots: {
        customRender: 'status'
    },
    align: 'center',
    width: '120px'
},
{
    title: '户名',
    dataIndex: 'customer_name',
    align: 'center',
    width: '140px'
},
{
    title: '施工地点',
    dataIndex: 'address',
    scopedSlots: {
        customRender: 'address'
    },
    align: 'center',
    width: '200px'
},
// {
//     title: '意向申报水表口径 DN',
//     dataIndex: 'caliber',
//     align: 'center',
//     width: '170px'
// },
{
    title: '联系人',
    dataIndex: 'name',
    align: 'center',
    width: '120px'
},
{
    title: '联系电话',
    dataIndex: 'mobile',
    align: 'center',
    width: '130px'
},
{
    title: '申请时间',
    dataIndex: 'create_time',
    scopedSlots: {
        customRender: 'time'
    },
    align: 'center',
    width: '180px'
},
{
    title: '受理时间',
    dataIndex: 'update_time',
    scopedSlots: {
        customRender: 'update_time'
    },
    align: 'center',
    width: '180px'
},
{
    title: '操作',
    dataIndex: 'action',
    width: '190px',
    scopedSlots: {
        customRender: 'action'
    },
    align: 'center'
}
]

const statusMap = {
    0: {
        status: 'default',
        text: '全部'
    },
    1: {
        status: 'default',
        text: '待审核'
    },
    2: {
        status: 'success',
        text: '审核通过'
    },
    3: {
        status: 'error',
        text: '审核拒绝'
    }
}

export default {
    name: '',
    components: {
        STable,
        Ellipsis,
        workbenchTab
    },
    data() {
        this.columns = columns
        return {
            // create model
            visible: false,
            advanced: false,
            // 查询参数
            queryParam: {
                time: [],
                auditStatus: '0',
                keyword: '',
                keyType: '3',
                startTime: '',
                endTime: '',
                worker: '',
                dataSource: '0'
            },
            // 加载数据方法 必须为 Promise 对象
            loadData: parameter => {
                //console.log('parameter',parameter)
                const requestParameters = Object.assign({}, parameter, this.queryParam)
                //console.log('requestParameters',requestParameters)
                return waterReportList(requestParameters)
                    .then(res => {
                        return res.data
                    })
            },
            active: 0,
            photo1: [],
            photo2: [],
            photo3: [],
            photo4: [],
            modalVisible: false, //回复弹窗
            reason: '', //审核理由
            statusMap: statusMap,
            info: '',   //详情
            id: '',     //列表id
            status: '',   //2 通过  3 拒绝
            isChecked: false,
            prefix: prefix,  //测试地址
            pageNum: 0,
            typeData: [],
            /*new*/
            type_name: {
                '0-0': '用户接用水申请',
                '1-0': '供用水合同',
                '2-0': '水费定期借记业务授权与协议书',
                '3-0': '用户委托书'
            },
            attachment: null,
        }
    },
    filters: {
        time(t) {
            if (t) {
                return moment(parseInt(t) * 1000).format('YYYY/MM/DD HH:mm:ss')
            } else {
                return t
            }
        },
        statusFilter(type) {
            return statusMap[type].text
        },
        statusTypeFilter(type) {
            return statusMap[type].status
        }
    },
    created() {
        this.pageNum = Number(getUrlKey('p') || 0)
        this.getType()
    },
    mounted() {
        //console.log('init=',init)
        if (!init) {
            init = setInterval(_ => {
                //console.log('get....')
                this.$refs && this.$refs.table.refresh();
            }, 20000)
        }
    },
    methods: {
        checkPrefixWithHttp: checkPrefixWithHttp,
        getSource: getSource,
        getType() {
            _getSourceList({}).then(res => {
                if (res.code == 200) {
                    this.typeData = res.data
                }
            })
        },
        reply() { //回复弹窗打开
            this.modalVisible = true
        },
        closeModal(status) { //回复弹窗关闭 1确定 2取消
            //console.log('status',status)
            const that = this
            const reason = this.reason
            const params = {
                auditOpinion: reason,
                id: this.id,
                auditStatus: 3
            }
            if (status == 1) {
                if (!reason) {
                    this.$message.error('请输入拒绝理由')
                    return false
                }
                Modal.confirm({
                    content: '确认拒绝吗?',
                    onOk() {
                        handleWaterReport(params).then(res => {
                            if (res.code == 200) {
                                message.success('处理成功')
                                that.visible = false
                                that.$refs.table.refresh()
                                that.reason = ''
                                that.id = ''
                                that.status = ''
                                that.modalVisible = false
                            } else {
                                message.error(res.msg)
                            }
                        })
                    },
                    onCancel() {
                        that.modalVisible = false
                    }
                })
            }
        },
        getTabId(e) {
            //console.log('e', e);
            if (e == 0) {
                this.queryParam.worker = ''
            } else if (e == 1) {
                this.queryParam.worker = 'A'
            } else if (e == 2) {
                this.queryParam.worker = 'B'
            }
            this.$refs.table.refresh(true)
        },
        getDate(date, dateString) { //时间筛选
            this.queryParam.time = dateString
            this.queryParam.startTime = this.queryParam.time[0] ? this.queryParam.time[0] + ' 00:00:00' : ''
            this.queryParam.endTime = this.queryParam.time[1] ? this.queryParam.time[1] + ' 23:59:59' : ''
        },
        getStatus(e) {
            //console.log('e', e);
            this.auditStatus = e
        },
        reset() { //重置
            this.queryParam.time = null
            this.queryParam.startTime = ''
            this.queryParam.endTime = ''
            this.queryParam.auditStatus = '0'
            this.queryParam.keyword = ''
            this.queryParam.keyType = '3'
            this.queryParam.dataSource = '0'
            this.$refs.table.refresh(true)
        },
        handleEdit(record, status) { //处理
            this.id = record.id
            // this.getDetail(record.id)
            // this.isChecked = false
            // this.visible = true
            this.onClose(status)
        },
        handleWatch(record) {
            this.id = record.id
            this.getDetail(record.id)
            this.isChecked = true
            this.visible = true
        },
        handlePre(record) { //预览
            return false
            const url = this.prefix + '/public/admin/h5/transfer_personal.html?id=' + record.id + '&token=' + localStorage.getItem('Access-Token')
            window.open(url)
        },
        getDetail(id) { //详情
            const params = {
                id: id
            }
            waterReportInfo(params).then(res => {
                //console.log('res',res)
                if (res.code == 200) {
                    this.info = res.data
                    this.attachment = res.data.attachment
                    this.$nextTick(_ => {
                        this.$previewRefresh()
                    })
                    return
                    const att = res.data.attachment
                    const temp = []
                    for (const i in att) {
                        temp.push(att[i] == 'null' ? '' : att[i])
                    }

                    const temp1 = temp[0] ? [temp[0]] : []
                    const temp2 = temp[1] ? [temp[1]] : []
                    const temp3 = temp[2] ? [temp[2]] : []
                    const temp4 = temp[3] ? [temp[3]] : []
                    console.log(temp1, temp2, temp3)
                    this.photo1 = temp1
                    this.photo2 = temp2
                    this.photo3 = temp3
                    this.photo4 = temp4
                    this.$previewRefresh()
                } else {
                    message.error(res.msg)
                }
            })
        },
        onClose(status) { //关闭右侧弹出层   2 通过  3 拒绝
            if (status == 2) {
                const that = this
                const reason = this.reason
                const params = {
                    auditOpinion: reason,
                    id: this.id,
                    auditStatus: 2
                }
                Modal.confirm({
                    content: '确认通过吗?',
                    onOk() {
                        handleWaterReport(params).then(res => {
                            if (res.code == 200) {
                                message.success('处理成功')
                                that.visible = false
                                that.$refs.table.refresh()
                                that.reason = ''
                                that.id = ''
                                that.status = ''
                                that.modalVisible = false
                            } else {
                                message.error(res.msg)
                            }
                        })
                    },
                    onCancel() {
                        that.modalVisible = false
                    }
                })
            } else if (status == 3) {
                this.modalVisible = true
            } else {
                this.visible = false
            }
        }
    },
    destroyed() {
        clearInterval(init)
        init = null
    }
}
</script>