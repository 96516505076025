import Rsa from "./66440687a73beb62.js"
export function getUrlKey(name){ //获取url 参数
  return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[,""])[1].replace(/\+/g,'%20'))||null;
}
//ueditor 路径
export const UEDITOR_HOME_URL = (location.hostname.indexOf('127.0.0.1')>-1||location.hostname.indexOf('192.168')>-1)?'/UEditor/':'/UEditor/';

//ueditor 服务器路径
export const serverUrl = location.hostname.indexOf('127.0.0.1')>-1?'/UEditor/php/controller.php':'/UEditor/php/controller.php';

//图片前缀  //https://gsapi.joyimedia.com
//export const prefix = process.env.VUE_APP_API_BASE_URL; //'https://wapi.hfwater.cn';  //wapi.hfwater.cn
export const prefix = 'https://wxapi.feixiwater.com/'; 
//图片上传
export const imgUpload ='https://wxapi.feixiwater.com/newfxgs/newAdmin.php?c=Upload&a=image2';
//export const imgUpload ='/api/newAdmin.php?c=Upload&a=image2';
//题库导入
export const qusUrl = 'https://wxapi.feixiwater.com/newfxgs/newAdmin.php?c=Operate&a=importQuestion';

export const hostUrl = process.env.VUE_APP_API_BASE_URL; //https://wapi.hfwater.cn';



export function checkPrefixWithHttp(url){
	//console.log('url',url);
	var res=/(http|https):\/\/([\w.]+\/?)\S*/.test(url);
	if(res){
		return true;
	}else{
		return false;
	}
}

// 数据来源：1:微信公众号营业厅；2.微信小程序营业厅；3:网站营业厅；4.智慧营业厅；9.其他；
export function getSource(type) {
    let obj = null
    if (type == 1) {
        obj = {
            type: 1,
            name: "公众号",
            color: 'green'
        }
    } else if (type == 2) {
        obj = {
            type: 2,
            name: "小程序",
            color: 'blue'
        }
    } else if (type == 3) {
        obj = {
            type: 3,
            name: "网厅",
            color: 'cyan'
        }
    } else if (type == 5) {
        obj = {
            type: 5,
            name: "合肥不动产",
            color: 'orange'
        }
    } else if (type == 9) {
        obj = {
            type: 9,
            name: "其它",
            color: 'purple'
        }
    } else if (type == 6) {
        obj = {
            type: 6,
            name: "长丰不动产",
            color: 'pink'
        }
    } else if (type == 8) {
        obj = {
            type: 8,
            name: "肥西不动产",
            color: 'green'
        }
    }
    else if (type == 10) {
        obj = {
            type: 10,
            name: "皖事通",
            color: 'purple'
        }
    } else if (type == 11) {
        obj = {
            type: 11,
            name: "支付宝",
            color: '#0091ff'
        }
    } else {
        obj = {
            type: '',
            name: '-',
            color: ''
        }
    }

    return obj
}



export const authData = {
	code:200,
	message:'',
	timestamp:1593431384023,
	result:{
		avatar:'',
		createTime:'',
		creatorId:'admin',
		id:"",
		name:'管理员',
		role:{
			id:'admin',
			name:"管理员",
			permissionList:["dashboard","service","agreement","article","notice","outlets","question","userManage"],
			permissions:[
				{
					actionEntitySet:[
						{action:'add',defaultCheck:false,describe:'新增'}
					],
					actionList:['add'],
					actions:"[{'action':'add'}]",
					dataAccess:null,
					permissionId:"dashboard",
					permissionName:"仪表盘",
					roleId:'admin'
				},
				{
					actionEntitySet:[
						{action:'add',defaultCheck:false,describe:'新增'}
					],
					actionList:['add'],
					actions:"[{'action':'add'}]",
					dataAccess:null,
					permissionId:"service",
					permissionName:"业务办理",
					roleId:'admin'
				}
			]
		},
		roleId:'admin',
		username:'admin'
	}
}

export const decrypt = (str) =>{
    return str?JSON.parse(Rsa.decrypt(str)):str;
}

export const encrypt = (str) =>{
    // console.log('str',str,Rsa.encrypt);
    return str?Rsa.encrypt(str):str;
}