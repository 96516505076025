<template>
    <div>
        <div class="workbench">
            <span :class="item.id==active?'workbenchAct':''" @click="change(item.id)" v-for="(item,index) in list" :key="index">{{item.name}}</span>
          </div>
    </div>
</template>

<script>
export default {
    name:'workbenchTab',
    props:{
        value:{
            type:String|Number,
            default:0
        }
    },
    created(){
        this.active = this.value;
    },
    data(){
        return {
            active:0,
            list:[
                {id:0,name:'全部'},
                {id:1,name:'工作台1'},
                {id:2,name:'工作台2'},
            ]
        }
    },
    methods:{
        change(id){
            this.active = id;
            this.$emit('change',id);
        }
    }
}
</script>